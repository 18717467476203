<template>
  <div class="gratitude-wall" v-if="loaded">
    <div>
      <h2>Gratitude wall</h2>
      <p class="donations">
        Your support means the world to us. At Tjing, team-play is one of our
        core values, and when we refer to our users as team members, we mean it.
        Thank you for wanting to be a part of this project and our team! If
        monthly contributions isn't your thing, a donation also makes a big
        difference. <br />Donate via
        <a
          href="https://www.paypal.me/tjingapp"
          target="_blank"
          rel="noreferrer"
          >PayPal</a
        >
        or
        <span @click="$refs.swish.open()">Swish</span>
      </p>
    </div>
    <div class="become-patreon">
      <a target="_blank" href="https://www.patreon.com/tjing">
        <button type="button" name="button" class="patreon-button">
          Become a Patreon
        </button>
      </a>
    </div>

    <div class="donations"></div>
    <div>
      <h3>Pro Backer</h3>
      <p>$20 or more per month</p>
      <template v-for="(patreon, index) in patreons.pro_backers">
        <prismic-rich-text :field="patreon.pro_backer" :key="'pro' + index" />
      </template>

      <h3>Generous backer</h3>
      <p>$10 or more per month</p>
      <template v-for="(patreon, index) in patreons.generous_backers">
        <prismic-rich-text
          :field="patreon.generous_backer"
          :key="'gen' + index"
        />
      </template>

      <h3>Backer</h3>
      <p>$5 or more per month</p>
      <template v-for="(patreon, index) in patreons.backers">
        <prismic-rich-text :field="patreon.backer" :key="'back' + index" />
      </template>

      <h3>Motivation boost</h3>
      <p>$1 or more per month</p>
      <template v-for="(patreon, index) in patreons.motivation_boost">
        <prismic-rich-text
          :field="patreon.motivation_boosters"
          :key="'boost' + index"
        />
      </template>

      <h3>Donations</h3>

      <div class="donors">
        <prismic-rich-text :field="patreons.donators" class="donor" />
      </div>
    </div>
    <sweet-modal
      :enable-mobile-fullscreen="false"
      ref="swish"
      hide-close-button
    >
      <img class="donation-qr-code" src="@/assets/img/tjing_swish_qr.png" />
      <p class="donation-swish-text">
        Scan the QR-code in your Swish app or Swish directly to:
        <b> 1236371124</b>
      </p>

      <StandardSolidButton
        title="Close"
        :fluid="false"
        @click="$refs.swish.close()"
      />
    </sweet-modal>
  </div>
</template>

<script>
import StandardSolidButton from "@/components/UIElements/StandardSolidButton";
export default {
  components: {
    StandardSolidButton,
  },
  name: "GratitudeWall",
  data() {
    return {
      loaded: false,
      patreons: null,
    };
  },
  methods: {
    getContent() {
      this.$prismic.client
        .getSingle("patreons")
        .then((document) => {
          if (document) {
            this.patreons = document.data;
            this.loaded = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  beforeMount() {
    this.getContent();
  },
};
</script>
export default {

<style lang="scss" >
@import "../styles/global-styles.scss";
@import "@/styles/Typography.scss";

.gratitude-wall {
  padding: 32px 15px;
  h2 {
    @include Gilroy-Bold;
    text-align: center;
    margin-bottom: 24px;
  }
  h3 {
    text-align: center;
    margin-top: 32px;
  }
  h4,
  h5 {
    text-align: center;
    margin-bottom: 4px;
  }

  p {
    text-align: center;
    margin-bottom: 18px;
  }

  .patreon-button {
    margin-top: 30px;
    margin-bottom: 20px;
    background-color: #f86754 !important;
    border-color: #f86754 !important;
    color: white;
    box-shadow: none;
    border: none;
    width: 100%;
  }

  .become-patreon {
    display: flex;
    justify-content: center;
    a {
      width: 100%;
    }
  }

  .gratitude-wall {
    margin-top: 64px;
    padding: 0 12px;
  }

  .donor {
    margin-top: 24px;
    p {
      line-height: 1.5rem;
    }
  }

  .donations {
    span,
    a {
      color: $midnight;
      text-decoration: underline;
      cursor: pointer;
      @include Gilroy-Bold;
    }
  }
  @media (min-width: 480px) and (orientation: landscape) {
  }

  @media (min-width: 544px) {
  }

  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    p {
      padding: 0 25%;
    }

    .become-patreon {
      a {
        width: auto;
      }
    }
  }
}
</style>
